import Api from './Api';

export default{

    getSliders(){
        return Api().get('/slider');
    },

    addSliders(slider){
        return Api().post('/slider/add',slider);
    },

    deleteSlider(id){
        return Api().delete(`/slider/delete/${id}`);
    }
}