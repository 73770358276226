<template>
  <div class="navigation">
    <div class="full">
      <nav>
        <a class="brand">
          <img src="../../assets/n_logo.png" />
        </a>
        <ul class="sticky">
          <li><router-link to="/home">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/gallery">Gallery</router-link></li>
          <li>
            <router-link to="/product">Products</router-link>
            <ul class="ul">
              <li>
                <router-link to="/poultry">Poultry</router-link>
              </li>

              <li>
                <router-link to="/ruminent">Ruminant</router-link>
              </li>
              <li>
                <router-link to="/aqua">Aqua</router-link>
              </li>
            </ul>
          </li>
          <li><router-link to="/partner">Partners</router-link></li>
          <li><router-link to="/contactus">Contact Us</router-link></li>
        </ul>
      </nav>
    </div>
    <!-- mobile view -->

    <div class="mini">
      <nav>
        <a class="brand">
          <div class="image">
            <img src="../../assets/n_logo.png" />
          </div>

          <div class="btn" @click="onClick()">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </div>
        </a>
        <ul class="sticky">
          <transition-group name="slide-fade">
            <li v-if="!show">
              <router-link to="/home" @click="onClick()">Home</router-link>
            </li>
            <li v-if="!show">
              <router-link to="/about" @click="onClick()">About</router-link>
            </li>
            <li v-if="!show">
              <router-link to="/gallery" @click="onClick()"
                >Gallery</router-link
              >
            </li>
            <li v-if="!show">
              <router-link to="/product">Products</router-link>
              <ul class="ul">
                <li v-if="!show">
                  <router-link to="/poultry" @click="onClick()"
                    >Poultry</router-link
                  >
                </li>

                <li v-if="!show">
                  <router-link to="/ruminent" @click="onClick()"
                    >Ruminant</router-link
                  >
                </li>
                <li v-if="!show">
                  <router-link to="/aqua" @click="onClick()">Aqua</router-link>
                </li>
              </ul>
            </li>
            <li v-if="!show">
              <router-link to="/partner" @click="onClick()"
                >Partners</router-link
              >
            </li>
            <li v-if="!show">
              <router-link to="/contactus" @click="onClick()"
                >Contact Us</router-link
              >
            </li>
          </transition-group>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },

  created() {},

  methods: {
    onClick() {
      this.show = !this.show;
      if (this.showSidebar) {
        this.showLink = true;
        setTimeout(() => {
          this.showSidebar = false;
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.navigation {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  position: fixed;
  z-index: 999;
  background: rgb(35, 2, 58);
}
.navigation .full {
  width: 100%;
  height: 120px;
  padding: 0;
  margin: 0;
}
.navigation .mini {
  display: none;
}

.full nav {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  justify-content: center;
  display: flex;
}
nav .brand {
  width: 30%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
}
nav .brand img {
  width: 102px;
  height: 92px;
  padding: 0;
  margin: 0;
}
nav .sticky {
  padding: 10px;
  margin-top: 30px;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: right;
}
nav .sticky li {
  list-style: none;
  padding: 5px;
  margin: 5px;
}
nav .sticky li a {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  padding: 5px;
  margin: 0;
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-bottom: 2px none #fff;
}
nav .sticky li a:hover,
nav .sticky li a.router-link-exact-active {
  border-bottom: 2px solid rgb(255, 255, 255);
}
.sticky li:hover .ul {
  display: block;
}
.sticky li .ul {
  width: 120px;
  height: auto;
  position: absolute;
  display: none;
  padding: 0;
  margin-top: 5px;
  margin-left: -20px;
  background: rgb(35, 2, 58);
}
.sticky li .ul li {
  display: block;
  width: 100%;
  height: 60px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.sticky li .ul li:hover {
  background: rgba(170, 23, 23, 0.664);
}
.sticky li .ul li a {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  padding: 10px;
  margin: 5px;
  border-bottom: 2px none #fff;
}
.sticky li .ul li:hover .two {
  display: block;
}
.ul li .two {
  width: 250px;
  height: auto;
  position: absolute;
  display: none;
  padding: 0;
  margin-top: 20px;
  margin-left: 370px;
  background: #123456ce !important;
}
.ul li .two li:hover {
  background: rgba(0, 0, 0, 0.445);
}

@media only screen and (max-width: 436px) {
  .navigation {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    position: fixed;
    z-index: 999;
    background: rgb(35, 2, 58);
  }
  .navigation .full {
    display: none;
  }
  .navigation .mini {
    width: 100%;
    height: 120px;
    padding: 0;
    margin: 0;
    display: block;
  }

  .mini nav {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    justify-content: block;
  }
  .mini nav .brand {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .mini nav .brand .image {
    width: 50%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .mini nav .brand .image img {
    width: 102px;
    height: 92px;
    padding: 0;
    margin: 0;
  }
  .mini nav .brand .btn {
    width: 50%;
    height: 100%;
    padding: 20px;
    margin: 0;
  }
  .mini nav .brand .btn i {
    font-size: 2.2rem;
    font-weight: 700;
    color: #fff;
    padding: 10px;
    margin-left: 100px;
  }
  .mini nav .sticky {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .mini nav .sticky li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: rgb(35, 2, 58);
    line-height: 50px;
  }

  nav .sticky li a:hover,
nav .sticky li a.router-link-exact-active {
  border-bottom: none;
}
  .mini nav .sticky li:hover .ul {
    position: relative;
    display: block;
    visibility: visible;
    width: 100%;
  }
  .mini .sticky li .ul {
    display: block;
    visibility: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .mini .sticky li .ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: rgb(35, 2, 58);
    line-height: 50px;
    width: 100%;
  }
  .mini .sticky li .ul li:hover,
  .mini .sticky li:hover {
    background: rgba(170, 23, 23, 0.664);
  }
  .mini .sticky li a,
  .mini .sticky li .ul li a {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Gentium Book Basic", serif;
    text-align: center;
    padding: 10px;
    margin: 5px;
    border-bottom: 2px none #fff;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(10%);
    opacity: 0;
  }
}
</style>