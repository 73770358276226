import Api from './Api';

export default{

    getPartners(){
        return Api().get('/partner');
    },

    addPartners(partner){
        return Api().post('/partner/add',partner);
    },

    deletePartner(id){
        return Api().delete(`/partner/delete/${id}`);
    }
}