<template>
  <div class="products">
    <div class="container">
      <h2>Products</h2>
      <div class="row">
        <div class="col-md-4" v-for="product in products" :key="product.id">
          <router-link :to="product.link">
            <div class="card">
              <div class="image">
                <img :src="product.image" />
              </div>
              <p>{{ product.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          id: 1,
          image: require("../../assets/p1.png"),
          name: "Poultry",
          link: "/poultry",
        },
        {
          id: 2,
          image: require("../../assets/c1.png"),
          name: "Ruminent",
          link: "/ruminent",
        },
        {
          id: 3,
          image: require("../../assets/a1.png"),
          name: "Aqua",
          link: "/aqua",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap");

.products {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
h2 {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  font-family: "Gentium Book Basic", serif;
  padding: 10px;
  margin: 0;
  color: rgb(35, 2, 58);
  text-transform: uppercase;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 120px;
}
.products .row {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 0;
}
.row .col-md-4 {
  width: 33%;
  height: 100%;
  padding: 10px;
  margin: 0;
}
.col-md-4 a {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.col-md-4 a .card {
  width: 100%;
  height: 300px;
  padding: 5px;
  margin: 0;
  display: block;
}

.card .image {
  width: 100%;
  height: 85%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  background: url(../../assets/back.jpg);
}
.card .image img {
  width: 100%;
  height: 100%;
}
.card p {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  color: #000;
  padding: 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  height: 15%;
  background: url("../../assets/back1.jpg");
}

@media only screen and (max-width: 436px) {
  .products {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    font-family: "Gentium Book Basic", serif;
    padding: 10px;
    margin: 0;
    color: rgb(35, 2, 58);
    text-transform: uppercase;
  }
  .container {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 120px;
  }
  .products .row {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
  }
  .row .col-md-4 {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
  }

  .col-md-4 .card {
    width: 100%;
    height: 300px;
    padding: 5px;
    margin: 0;
    display: block;
  }
  .card .image {
    width: 100%;
    height: 85%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    background: url(../../assets/back.jpg);
  }
  .card .image img {
    width: 100%;
    height: 100%;
  }
  .card a {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Gentium Book Basic", serif;
    text-align: center;
    /* display: flex;
  justify-content: center; */
    color: #000;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    height: 15%;
    background: url("../../assets/back1.jpg");
  }
}
</style>