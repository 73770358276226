<template>
  <div class="products">
    <div class="container">
      <h2>Aqua</h2>
      <div class="row">
         <div class="col-md-3" v-for="product in products" :key="product.id">
          <div class="card">
            <div class="image">
              <img :src="'../storage/app/' + product.pimage" />
            </div>
            <h4>{{ product.pname }}</h4>
            <p>{{ product.pdescription }}</p>
            <a :href="'../storage/app/' + product.ppdf">Pdf</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '../../apis/Product';

export default {
  name:'aqua',
  data() {
    return {
      products: [],
    };
  },
  created(){
    this.getAqua();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods:{
    getAqua(){
      Product.getAqua().then((res)=>{
        this.products = res.data;
      })
    }
  }
};
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap");

.products {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
h2 {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  font-family: "Gentium Book Basic", serif;
  padding: 10px;
  margin: 0;
  color: rgb(35, 2, 58);
  text-transform: uppercase;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 120px;
}
.products .row {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 0;
}
.row .col-md-3 {
  width: 24%;
  height: 300;
  padding: 10px;
  margin: 0;
}

.col-md-3 .card {
  width: 100%;
  height: 450px;
  padding: 0;
  margin: 10px;
  display: block;
  margin: 10px;
}
.col-md-3 .card .image {
  width: 100%;
  height: 50%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  background: url("../../assets/back1.jpg");
}
.card .image img {
  width: 100%;
  height: 100%;
}
.card h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  color: #000;
  padding: 10px;
  margin: 0;
  width: 100%;
  height: 10%;
}
.card p {
  font-size: 0.9rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  color: #000;
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 30%;
}
.card a {
  width: 100%;
  height: 10%;
  background: rgb(35, 2, 58);
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-decoration: none;
  display: flex;
  justify-content: center;
  color: #fff;
}

@media only screen and (max-width: 436px) {
  .products {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    font-family: "Gentium Book Basic", serif;
    padding: 10px;
    margin: 0;
    color: rgb(35, 2, 58);
    text-transform: uppercase;
  }
  .container {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 120px;
  }
  .products .row {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
  }
  .row .col-md-3 {
    width: 100%;
    height: 300;
    padding: 10px;
    margin: 0;
  }

  .col-md-3 .card {
    width: 100%;
    height: 450px;
    padding: 0;
    margin: 10px;
    display: block;
    margin: 10px;
  }
  .col-md-3 .card .image {
    width: 100%;
    height: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    background: url("../../assets/back1.jpg");
  }
  .card .image img {
    width: 100%;
    height: 100%;
  }
.card h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  color: #000;
  padding: 10px;
  margin: 0;
  width: 100%;
  height: 10%;
}
.card p {
  font-size: 0.9rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: justify;
  color: #000;
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 30%;
}
.card a {
  width: 100%;
  height: 10%;
  background: rgb(35, 2, 58);
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-decoration: none;
  display: flex;
  justify-content: center;
  color: #fff;
}
}
</style>