<template>
  <div class="gallery">
    <div class="container">
      <h2>hall of agrotech</h2>
      <div class="row">
        <div class="col-md-4" v-for="gallery in gallerys" :key="gallery.id">
          <div class="card">
            <img :src="'../storage/app/'+ gallery.gimage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from "../../apis/Gallery";

export default {
  data() {
    return {
      gallerys: [],
    };
  },
  created() {
    this.getGallery();
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    getGallery() {
      Gallery.getGallerys().then((response) => {
        this.gallerys = response.data;
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap");

.gallery {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

h2 {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  font-family: "Gentium Book Basic", serif;
  padding: 10px;
  margin: 0;
  color: rgb(35, 2, 58);
  text-transform: uppercase;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 120px;
}
.gallery .row {
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 0;
  display: flex;
}
.row .col-md-4 {
  width: 30%;
  height: auto;
  padding: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.row .col-md-4 .card {
  width: 100%;
  height: 280px;
  padding: 10px;
  margin: 10px;
  border: 10px double rgb(145, 145, 35);
}
.box .col-md-4 .card img {
  width: 100%;
  height: 260px;
  padding: 10px;
  margin: 0;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 436px) {
  .gallery {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    font-family: "Gentium Book Basic", serif;
    padding: 10px;
    margin: 0;
    color: rgb(35, 2, 58);
    text-transform: uppercase;
  }
  .container {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 120px;
  }
  .gallery .row {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
  }
  .row .col-md-4 {
    width: 100%;
    height: auto;
    padding: auto;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .row .col-md-4 .card {
    width: 100%;
    height: 280px;
    padding: 10px;
    margin: 10px;
    border: 10px double rgb(145, 145, 35);
  }

  .box .col-md-4 .card img {
    width: 100%;
    height: 260px;
    padding: 10px;
    margin: 0;
    background-size: cover;
    background-position: center;
  }
}
</style>