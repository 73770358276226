import Api from './Api';

export default {

    getGallerys() {
        return Api().get('/gallery');
    },

    addGallerys(gallery) {
        return Api().post('/gallery/add', gallery);
    },

    deleteGallery(id){
        return Api().delete(`/gallery/delete/${id}`);
    }
}