<template>
  <div class="partners">
    <div class="container">
      <h2>Partners</h2>
      <div class="row">
        <div class="col-md-4" v-for="partner in partners" :key="partner.id">
          <div class="card">
            <div class="image">
              <img :src="'../storage/app/' + partner.ptimage" />
            </div>
            <p>{{ partner.ptname }}</p>
            <a :href="partner.ptlink">Visit</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Partner from "../../apis/Partner";

export default {
  data() {
    return {
      partners: [],
    };
  },
  created(){
    this.getPartner();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    getPartner() {
      Partner.getPartners().then((res) => {
        this.partners = res.data;
      });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap");

.products {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
h2 {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  font-family: "Gentium Book Basic", serif;
  padding: 10px;
  margin: 0;
  color: rgb(35, 2, 58);
  text-transform: uppercase;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 120px;
}
.row {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 0;
}
.row .col-md-4 {
  width: 25%;
  height: 100%;
  padding: 10px;
  margin: 10px;
}

.col-md-4 .card {
  width: 100%;
  height: 300px;
  padding: 5px;
  margin: 0;
  display: block;
  background: url(../../assets/back1.jpg);
}
.card .image {
  width: 100%;
  height: 75%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  /* background: url(../../assets/back.jpg); */
}
.card .image img {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0;
}
.card p {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  color: #000;
  padding: 5px;
  margin: 0;
  height: 15%;
}
.card a {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #000;
  background: rgba(110, 19, 19, 0.767);
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 436px) {
  .products {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    font-family: "Gentium Book Basic", serif;
    padding: 10px;
    margin: 0;
    color: rgb(35, 2, 58);
    text-transform: uppercase;
  }
  .container {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 120px;
  }
  .row {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
  }
  .row .col-md-4 {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 10px;
  }

  .col-md-4 .card {
    width: 100%;
    height: 300px;
    padding: 5px;
    margin: 0;
    display: block;
    background: url(../../assets/back1.jpg);
  }
  .card .image {
    width: 100%;
    height: 75%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    /* background: url(../../assets/back.jpg); */
  }
  .card .image img {
    width: 100%;
    height: 100%;
    padding: 5px;
    margin: 0;
  }
  .card p {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "Gentium Book Basic", serif;
    text-align: center;
    color: #000;
    padding: 5px;
    margin: 0;
    height: 15%;
  }
  .card a {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "Gentium Book Basic", serif;
    text-align: center;
    padding: 0;
    margin: 0;
    color: #000;
    background: rgba(110, 19, 19, 0.767);
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
  }
}
</style>