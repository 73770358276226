import Api from './Api';

export default{

    getSlogans(){
        return Api().get('/slogan');
    },

    addSlogans(slogan){
        return Api().post('/slogan/add',slogan);
    },

    deleteSogan(id){
        return Api().delete(`/slogan/delete/${id}`);
    }
}