<template>
  <div class="contactus">
    <div class="row">
      <h2>Contact Us</h2>
      <div class="col-md-12">
        <div class="first">
          <h5>Office</h5>
          <address>
            House: 08, Block: D, Main Road<br />
            Easturn Housing, Pallobi 2nd Phase,<br />
            Rupnagar, Mirpur, Dhaka-1216.
          </address>
        </div>
        <div class="second">
          <ul>
            <li>
              <a href="https://www.facebook.com/"
                ><i class="fa fa-facebook" aria-hidden="true"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/"
                ><i class="fa fa-linkedin" aria-hidden="true"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/"
                ><i class="fa fa-twitter" aria-hidden="true"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap');


.contactus {
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 120px;
}
h2 {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
 font-family: 'Gentium Book Basic', serif;
  padding: 10px;
  margin: 0;
  color: rgb(35, 2, 58);
  text-transform: uppercase;
}
.contactus .row {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  background: rgba(194, 6, 6, 0.61);
}
.row .col-md-12 {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
}
.row .col-md-12 .first {
  width: 50%;
  height: 100%;
  padding: 10px;
  margin: 0;
}
.row .col-md-12 .first h5 {
  font-size: 1.5rem;
  font-weight: 600;
font-family: 'Gentium Book Basic', serif;
  padding: 5px;
  margin: 5px;
  text-align: center;
  text-transform: uppercase;
  background: rgb(35, 2, 58);
  color: #fff;
}
.row .col-md-12 .first address {
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  font-family: 'Gentium Book Basic', serif;
  border-right: 2px solid rgb(35, 2, 58);
}
.row .col-md-12 .second {
  width: 50%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
}
.row .col-md-12 .second ul {
  width: 40%;
  padding: 10px;
  margin-top: 20px;
  display: flex;
}
.row .col-md-12 .second ul li {
  padding: 5px;
  margin: 10px;
  list-style: none;
}
.row .col-md-12 .second ul li a i {
  font-size: 2.2rem;
  font-weight: 700;
  padding: 20px;
  margin: auto;
  color: rgb(194, 6, 6);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgb(35, 2, 58);
}

@media screen and (max-width: 436px) {
  .contactus {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-top: 120px;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    font-family: 'Gentium Book Basic', serif;
    padding: 10px;
    margin: 0;
    color: rgb(35, 2, 58);
    text-transform: uppercase;
  }
  .contactus .row {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    background: rgba(194, 6, 6, 0.61);
  }
  .row .col-md-12 {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .row .col-md-12 .first {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
  }
  .row .col-md-12 .first h5 {
    font-size: 1.5rem;
    font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
    padding: 5px;
    margin: 5px;
    text-align: center;
    text-transform: uppercase;
    background: rgb(35, 2, 58);
    color: #fff;
  }
  .row .col-md-12 .first address {
    padding: 10px;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  font-family: 'Gentium Book Basic', serif;
    border-right: none;
  }
  .row .col-md-12 .second {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .row .col-md-12 .second ul {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .row .col-md-12 .second ul li {
    padding: 5px;
    margin: 10px;
    list-style: none;
  }
  .row .col-md-12 .second ul li a i {
    font-size: 2.2rem;
    font-weight: 700;
    padding: 20px;
    margin: auto;
    color: rgb(194, 6, 6);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgb(35, 2, 58);
  }
}
</style>