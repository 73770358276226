import Api from './Api';

export default{
    getProducts(){
        return Api().get('/product');
    },

    addProducts(product){
        return Api().post('/product/add',product);
    },

    deleteProduct(id){
        return Api().delete(`/product/delete/${id}`);
    },

    getPoultry(){
        return Api().get('/product/poultry');
    },

    getRuminent(){
        return Api().get('/product/ruminent');
    },

    getAqua(){
        return Api().get('/product/aqua');
    }

}