<template>
  <div class="login">
    <div class="row my-3">
      <div class="col-md-6">
        <h2>Login Form</h2>
        <hr />
        <form class="msg">
          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              v-model="form.email"
              placeholder="enter email"
            />
          </div>
          <div class="form-group">
            <label>Password</label>
            <input class="form-control" type="password" v-model="form.password" />
          </div>
          <div class="mt-3">
            <button class="btn btn-primary" @click.prevent="login">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import User from "../../apis/User";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        device_name: "browser",
      },
      errors: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    login() {
      User.login(this.form)
        .then((response) => {
          this.$root.$emit("login", true);
          localStorage.setItem("token", response.data);
          this.$router.push("/admin");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap");

.login {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  /* background: rgba(63, 62, 62, 0.952); */
}
.login .row {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
}
.col-md-6 {
  width: 50%;
  height: 750px;
  margin-top: 80px;
  box-shadow: 0 0 10px blue;
  z-index: 1;
  background: #fff;
  background-image: url("../../assets/login.jpg");
  background-position: center;
  background-size: cover;
}
h2 {
  margin-top: 400px;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 900;
  font-family: "Gentium Book Basic", serif;
  text-transform: uppercase;
}
.msg {
  justify-content: center;
  padding: 10px;
}
label {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Gentium Book Basic", serif;
  padding: 5px;
  margin: 0;
}
.btn {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Gentium Book Basic", serif;
  letter-spacing: 1px;
}
.error {
  color: red;
}

@media only screen and (max-width: 436px) {
  .login {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    /* background: rgba(63, 62, 62, 0.952); */
  }
  .login .row {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .row .col-md-6 {
    width: 100%;
    height: 100%;
    margin-top: 0;
    box-shadow: 0 0 10px blue;
    z-index: 1;
    background: #fff;
    /* background-image: url("../../assets/login.jpg"); */
    background-position: center;
    background-size: cover;
  }
  h2 {
    margin-top: 100px;
    color: #000;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 900;
    font-family: "Gentium Book Basic", serif;
    text-transform: uppercase;
  }
  .msg {
    justify-content: center;
    padding: 10px;
  }
  label {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Gentium Book Basic", serif;
    padding: 5px;
    margin: 0;
  }
  .btn {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Lobster", cursive;
    letter-spacing: 1px;
  }
  .error {
    color: red;
  }
}
</style>