<template>
  <div class="footer">
    <div class="row">
      <div class="col-md-4 text">
        <h3>We are</h3>
        <p>
          Agrotech Global BD Nutrition is a World Class Animal Health Care
          Solution provider committed to deal with a wide range of world
          renowned products to market inside the Country through its own
          Distribution Network.
        </p>
      </div>
      <div class="col-md-4 add">
        <h3>Get in touch</h3>
        <address>
          House: 08, Block: D, Main Road<br />
          Easturn Housing, Pallobi 2nd Phase,<br />
          Rupnagar, Mirpur, Dhaka-1216.
        </address>
        <address>
          <strong> Email :</strong> agbdn05@gmail.com<br />
          <strong> Mobile No :</strong> +8801752-827682, +8801711-618565
        </address>
      </div>

      <div class="col-md-4 link">
        <h3>Quick link</h3>
        <div class="box">
          <ul class="one">
            <li><router-link to="/home">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/gallery">Gallery</router-link></li>
            <li><router-link to="/product">Product</router-link></li>
            <li><router-link to="/partner">Partner</router-link></li>
            <li><router-link to="/contactus">Contact Us</router-link></li>
             <li><router-link to="/login">Login</router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>All right reserved in &copy; <a href="https://www.ultrawave.digital/" target="_blank">Ultrawave.Digital</a></p>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap');

.footer {
  width: 100%;
  height: 100%;
  background: #1b1b1b;
  padding: 0;
  margin: 0;
  position: relative;
}
.row {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 0;
  background: rgb(35, 2, 58);
}
.row .col-md-4 {
  width: 32%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: block;
}
.col-md-4 h3 {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
     font-family: 'Gentium Book Basic', serif;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
}
.col-md-4 p {
  font-size: 1.1rem;
  font-weight: 400;
     font-family: 'Gentium Book Basic', serif;
  text-align: justify;
  padding: 10px;
  margin: 0;
  color: #fff;
}
.col-md-4 address {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
     font-family: 'Gentium Book Basic', serif;
  padding: 10px;
  margin: 5px;
  color: #fff;
}
.row .link {
  width: 30%;
  height: 100%;
  padding: 5px;
  margin: 0;
}
.link h3 {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
     font-family: 'Gentium Book Basic', serif;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
}
.link .box {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.link .box .one {
  width: 60%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: block;
}
.link .box .one li {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: flex;
  justify-content: center;
}
.link .box .one li a {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
   font-family: 'Gentium Book Basic', serif;
  padding: 0;
  margin: 0;
}
.link .box .two {
  width: 40%;
  padding: 10px;
  margin-top: 20px;
  display: block;
}
.link .box .two li {
  padding: 5px;
  margin: 10px;
  list-style: none;
}
.link .box .two li a i {
  font-size: 2.2rem;
  font-weight: 700;
  padding: auto;
  margin: auto;
  color: #fff;
}

.footer .bottom {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 10px #fff;
  background: rgb(35, 2, 58);
}
.bottom p {
  font-size: 1rem;
  font-weight: 500;
     font-family: 'Gentium Book Basic', serif;
  color: rgb(212, 212, 212);
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 436px) {
  .footer {
    width: 100%;
    height: 100%;
    background: #1b1b1b;
    padding: 0;
    margin: 0;
    position: relative;
  }
  .row {
    display: block;
    padding: 10px;
    margin: 0;
    background: rgb(35, 2, 58);
  }
  .row .col-md-4 {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .col-md-4 h3 {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
      font-family: 'Gentium Book Basic', serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
  }
  .col-md-4 p {
    font-size: 1.1rem;
    font-weight: 400;
       font-family: 'Gentium Book Basic', serif;
    text-align: justify;
    padding: 10px;
    margin: 0;
    color: #fff;
  }
  .col-md-4 address {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
      font-family: 'Gentium Book Basic', serif;
    padding: 10px;
    margin: 5px;
    color: #fff;
  }
  .row .link {
    width: 100%;
    height: 100%;
    padding: 5px;
    margin: 0;
  }
  .link h3 {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
       font-family: 'Gentium Book Basic', serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
  }
  .link .box {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .link .box .one {
    width: 60%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .link .box .one li {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .link .box .one li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
      font-family: 'Gentium Book Basic', serif;
    padding: 0;
    margin: 0;
  }
  .link .box .two {
    width: 40%;
    padding: 10px;
    margin-top: 20px;
    display: block;
  }
  .link .box .two li {
    padding: 5px;
    margin: 10px;
    list-style: none;
  }
  .link .box .two li a i {
    font-size: 2.2rem;
    font-weight: 700;
    padding: auto;
    margin: auto;
    color: #fff;
  }

  .footer .bottom {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px #fff;
    background: rgb(35, 2, 58);
  }
  .bottom p {
    font-size: 1rem;
    font-weight: 500;
      font-family: 'Gentium Book Basic', serif;
    color: rgb(212, 212, 212);
  }
}
</style>