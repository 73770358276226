<template>
  <div class="home">
    <Details />
    <Slider />

    <!-- product -->

    <div class="items">
      <h2>WE PROVIDE</h2>
      <div class="container">
        <div class="row">
          <div class="col-md-3" v-for="product in products" :key="product.id">
              <router-link :to="product.url">
              <div class="card">
                <div class="image">
                  <img :src="product.image"/>
                </div>
                <p>{{product.name}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- ceo speach-->
    <div class="ceo">
      <h2>Introduction with ceo</h2>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="image">
              <img src="../../assets/ceo.jpg" />
            </div>
            <div class="info">
              <h3>Dr. Md. Jasim Uddin</h3>
              <p>DVM, MS(BAU), MBA(BUBT)</p>
              <p>Chief Executive Officer</p>
              <p>Agrotech Global BD Nutrition</p>
            </div>
          </div>
          <div class="col-md-9">
            <!-- autoplay -->
            <video controls autoplay>
              <source src="../../assets/video/speach.mp4" type="video/mp4" />
              <source src="speach.ogg" type="video/ogg" />
            </video>
          </div>
        </div>

        <div class="row2">
          <div class="speach">
            <div class="box" v-for="speach in speaches" :key="speach.id">
              <div class="tx" >
                <h4>{{ speach.title }}</h4>
                <p>
                  {{ speach.des }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "../services/Slider.vue";
import Slogan from "../../apis/Slogan";
import Details from "../services/Details.vue";

export default {
  name: "home",
  components: {
    Slider,
    Details,
  },
  data() {
    return {
      count: [],
      speaches: [
        {
          id: 1,
          title: "Message from CEO ",
          des: "Greetings from Agrotech Global BD Nutrition! We are established at 2019 with a good view to establish ourselves. Our approach to sustainability and supports the service and delivery of our own strategic priorities, specifically the provision of a safe and sustainable environment and engagement, development and retention of people.",
        },
        {
          id: 2,
          title: "Customer contentment",
          des: "Our number one goal is to ensure “Ensure Quality Products Beyond your Expectations” along with smooth delivery of goods. This includes a smooth ordering process, reliable turnaround times and other related issues. We realize we may not meet that goal 100% of the time, but we won’t stop improving until we do. ",
        },
        {
          id: 3,
          title: "Global Partner",
          des: "We have world class partner liked Tex Bioscience (P) Ltd. From Chennai, India and Linker Nutrients from France. World class manufacturing facility with them.",
        },
        {
          id: 4,
          title: "World-Class Support",
          des: "We staff operations personnel 24/7 so that we can always be responsive to your immediate needs. We answer most emails within an hour and strive to answer all emails the same day. We understand that customers often have specialized requirements, and we always do our best to accommodate them. ",
        },
        {
          id: 5,
          title: "National Customer Base ",
          des: "We are committed to serve National & International base of customer. Presently we serve Nourish Poultry Ltd, Kazi farms Ltd, Provita Hatcheries Ltd. Abir Poultry Ltd, Sadeh Farms Ltd, VIP Shahadat hatcheries ltd, Goalondo Hatcheries Ltd, etc. We will continue to invest to develop good quality products, people and sustainable policies with competitive prices.",
        },
        {
          id: 6,
          title: "In précis",
          des: "We are committed to serving a growing, satisfied, national and laterly international customer base by consistently and reliably providing affordable, fast, best quality products with world-class customer support.",
        },
      ],
      products: [
        {
          id: 1,
          image: require("../../assets/p1.png"),
          name: "Poultry",
          url: "poultry",
        },
        {
          id: 2,
          image: require("../../assets/c1.png"),
          name: "Ruminent",
          url: "ruminent",
        },
        {
          id: 3,
          image: require("../../assets/a1.png"),
          name: "Aqua",
          url: "aqua",
        },
      ],
      slogans: [],
    };
  },
  // created() {
  //   this.getSlogan();
  // },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    onclick(count) {
      this.count++;
      return this.count;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap');

.home {
  width: 100%;
  height: 100%;
  margin-top: 120px;
  padding: 0;
  background: rgba(194, 6, 6, 0.61);
  display: block;
}

.home .items {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.items h2,
.ceo h2 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
    font-family: 'Gentium Book Basic', serif;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: 0;
  color: rgb(35, 2.58);
  text-transform: uppercase;
}
.items .row {
  padding: 10px;
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.items .row .col-md-3 {
  width: 33%;
  height: 100%;
  padding: 10px;
  margin: 0;
}
.items .row .col-md-3 a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.items .row .col-md-3 a .card {
  width: 100%;
  height: 300px;
  padding: 0;
  margin: 0;
  background: rgb(35, 2, 58);
  border: none;
}
.items .row .col-md-3 .card .image {
  width: 100%;
  height: 85%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: url(../../assets/back1.jpg);
}
.items .row .col-md-3 .card .image img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: 0.5s transform ease-out;
}
.items .row .col-md-3 .card .image img:hover {
  transform: scale(1.1);
  transition: 0.3s transform ease-out;
}
.items .row .col-md-3 .card p {
  width: 100%;
  height: 15%;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  text-align: center;
  padding: 5px;
  margin: 0;
  color: #000;
  background: #fff;
}
.home .ceo {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.ceo .row {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
}
.ceo .row .col-md-3 {
  width: 30%;
  height: 100%;
  padding: 5px;
  margin: 0;
  display: block;
  border: 5px double blue;
  background: goldenrod;
}
.ceo .row .col-md-3 .image {
  width: 300px;
  height: 250px;
  padding: 0;
  margin: 0;
  display: block;
}
.ceo .row .col-md-3 .image img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  transform: scale(0.9);
}
.ceo .row .col-md-3 .info{
  width: 100%;
  height: 100px;
  padding: 5px;
  margin: 0;
}
.ceo .row .col-md-3 .info h3 {
  width: 100%;
  height: auto;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
    font-family: 'Gentium Book Basic', serif;
  padding: 0;
  margin: 0;
}
.ceo .row .col-md-3 .info p{
width: 100%;
height: auto;
font-size: 1rem;
font-weight: 500;
text-align: center;
font-family: 'Gentium Book Basic', serif;
padding: 0;
margin: 0;
}

.ceo .row .col-md-9 {
  width: 65%;
  height: 350px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px;
}
.ceo .row .col-md-9 video {
  width: 100%;
  height: 100%;
  display: block;
}

.ceo .row2 .speach {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 15px double rgb(55, 25, 138);
  border-radius: 20px;
  background: #fff;
}
.ceo .row2 .speach h4 {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  font-family: 'Gentium Book Basic', serif;
  padding: 5px;
  margin: 5px;
}
.ceo .row2 .speach p {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  font-family: 'Gentium Book Basic', serif;
  padding: 5px;
  margin: 0;
}

@media only screen and (max-width: 436px) {
  .home {
    width: 100%;
    height: 100%;
    margin-top: 120px;
    padding: 0;
    background: rgba(194, 6, 6, 0.61);
    display: block;
  }

  .home .items {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .items h2,
  .ceo h2 {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  font-family: 'Gentium Book Basic', serif;
    padding-top: 60px;
    padding-bottom: 10px;
    margin: 0;
    color: rgb(35, 2.58);
    text-transform: uppercase;
  }
  .items .row {
    padding: 10px;
    margin: 0;
    width: 100%;
    height: auto;
    display: block;
  }
  .items .row .col-md-3 {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
  }
  .items .row .col-md-3 a {
    text-decoration: none;
    padding:0;
    margin: 0;
  }
  .items .row .col-md-3 a .card {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0;
    background: rgb(35, 2, 58);
    border: none;
  }
  .items .row .col-md-3 .card .image {
    width: 100%;
    height: 85%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: url(../../assets/back1.jpg);
  }
  .items .row .col-md-3 .card .image img {
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: 0.5s transform ease-out;
  }
  .items .row .col-md-3 .card .image img:hover {
    transform: scale(1.1);
    transition: 0.3s transform ease-out;
  }
  .items .row .col-md-3 .card p {
    width: 100%;
    height: 15%;
    font-size: 1.8rem;
    font-weight: 600;
      font-family: 'Gentium Book Basic', serif;
    text-align: center;
    padding: 5px;
    margin: 0;
    color: #000;
    background: #fff;
  }
  .home .ceo {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .ceo .row {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .ceo .row .col-md-3 {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 0;
    display: block;
  }
  .ceo .row .col-md-3 .image {
    width: 320px;
  height: 250px;
  padding: 0;
  margin: 0;
  display: block;

  }
  .ceo .row .col-md-3 .image img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .ceo .row .col-md-3 .info{
  width: 100%;
  height: 100px;
  padding: 5px;
  margin: 0;
}
.ceo .row .col-md-3 .info h3 {
  width: 100%;
  height: auto;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
    font-family: 'Gentium Book Basic', serif;
  padding: 0;
  margin: 0;
}
.ceo .row .col-md-3 .info p{
width: 100%;
height: auto;
font-size: 1rem;
font-weight: 500;
text-align: center;
font-family: 'Gentium Book Basic', serif;
padding: 0;
margin: 0;
}
  .ceo .row .col-md-9 {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 10px;
  }
  .ceo .row .col-md-9 video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .ceo .row2 .speach {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 5px double rgb(55, 25, 138);
  }
  .ceo .row2 .speach h4 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
      font-family: 'Gentium Book Basic', serif;
    padding: 5px;
    margin: 5px;
  }
  .ceo .row2 .speach p {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
      font-family: 'Gentium Book Basic', serif;
    padding: 5px;
    margin: 0;
  }
}
</style>
