import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Client from '../views/pages/Clients.vue';
import Home from '../views/pages/Home.vue';
import About from '../views/pages/About.vue';
import Login from '../views/pages/Login.vue';
import Gallery from '../views/pages/Gallery.vue';
import Product from '../views/pages/Product.vue';
import Partner from '../views/pages/Partner.vue';
import Contactus from '../views/pages/Contactus.vue';

import Poultry from '../views/subpages/Poultry.vue';
import Ruminent from '../views/subpages/Ruminent.vue';
import Aqua from '../views/subpages/Aqua.vue';

import Admin from '../views/admin/Admin.vue';



const routes = [
  {
    path: '/',
    component: Client,
    redirect: '/home',
    eta: { guestOnly: true },
    children: [
      { path: 'home', component: Home },
      { path: 'about', component: About },
      { path: 'product', component: Product },
      { path: 'gallery', component: Gallery },
      { path: 'partner', component: Partner },
      { path: 'contactus', component: Contactus },
      { path: 'login', component: Login },

      { path: 'poultry', name:'poultry', component: Poultry},
      { path: 'ruminent', name:'ruminent', component: Ruminent },
      { path: 'aqua', name:'aqua', component: Aqua },
    ]
  },
  {
    path: '/admin',
    component: Admin,
    redirect: '/admin/dashboard',
    meta: { authOnly: true },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard.vue')
      },
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/admin/User.vue')
      },
      {
        path: 'gallerys',
        name: 'Gallery',
        component: () => import(/* webpackChunkName: "gallerys" */ '../views/admin/Gallerys.vue')
      },
      {
        path: 'partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/admin/Partners.vue')
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/admin/Products.vue')
      },
      {
        path: 'slider',
        name: 'slider',
        component: () => import(/* webpackChunkName: "slider" */ '../views/admin/Slider.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isLoggedIn() {
  return localStorage.getItem("token");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {

    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
