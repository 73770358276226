import Api from './Api';

export default{

    addSlogan(slogan){
        return Api().post('/slogan/add',slogan);
    },

    getSlogans(){
        return Api().get('/slogan');
    },

    register(form){
        return Api().post('/register',form);
    },

    login(form){
        return Api().post('/login',form);
    },

    logout(){
        return Api().post('/logout');
    },
    auth(){
        return Api().get('/user');
    },

    getUser(){
        return Api().get('/user');
    }

}