<template>
  <div class="admin">
    <div class="page-wrapper">
      <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
          <!-- sidebar-header  -->
          <div class="sidebar-item sidebar-header">
            <div class="user-pic">
              <img
                class="img-responsive img-rounded"
                src="../../assets/logo.png"
                alt="User picture"
              />
            </div>
          </div>
          <!-- sidebar-menu  -->
          <div class="sidebar-item sidebar-menu">
            <h3>Menu</h3>
            <ul>
              <li>
                <router-link to="/admin/dashboard">
                  <span>DashBoard</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/user">
                  <span>User</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/gallerys">
                  <span>Gallery</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/partners">
                  <span>Partner</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/products">
                  <span>Product</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/slider">
                  <span>Slider</span>
                </router-link>
              </li>
              <li>
                <button class="btn btn-primary" @click.prevent="logout">
                  Logout
                </button>
              </li>
            </ul>
          </div>
          <!-- sidebar-menu  -->
        </div>
      </nav>
      <!-- sidebar-content  -->
      <main class="page-content">
        <router-view></router-view>
      </main>
      <!-- page-content" -->
    </div>
    <!-- page-wrapper -->
  </div>
</template>

<script>
import User from '../../apis/User';

export default {
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    User.auth().then((response) => {
      this.user = response.data;
    });
  },
  methods: {
    logout() {
      User.logout().then(() => {
        localStorage.removeItem("token");
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style scoped>
.admin {
  padding: 0;
  margin: 0;
}
.page-wrapper {
  width: 100%;
  height: 100%;
  background: rgb(233, 231, 231);
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
nav {
  width: 20%;
  height: 100%;
  background: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px green;
}
.sidebar-content {
  position: relative;
  border-right: 2px solid green;
}
.sidebar-content .sidebar-header {
  width: 100%;
  height: auto;
  background: rgb(35, 2, 58);
  padding: 0;
  margin: 0;
  display: block;
}
.sidebar-header .user-pic {
  padding: 10px;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
}
.sidebar-header .user-pic img {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0;
}
.sidebar-content .sidebar-menu {
  display: flex;
  flex-direction: column;
}
.sidebar-menu h3 {
  font-size: 2.2rem;
  font-weight: 600;
  padding: 20px;
  margin: 0;
  font-family: "Lobster", cursive;
  text-align: center;
  background: rgb(29, 170, 83);
}
.sidebar-menu ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
ul li {
  list-style: none;
  height: 60px;
}
ul li a {
  display: flex;
  justify-content: left;
  text-decoration: none;
  padding: 10px;
}
ul li a span {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  font-family: "Lobster", cursive;
}
ul li:hover {
  background: rgba(75, 182, 107, 0.589);
}
.page-content {
  width: 80%;
  height: 100%;
  padding: 5px;
  background: #fff;
  position: inherit;
  margin-left: 20%;
}
</style>
