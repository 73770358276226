<template>
  <div class="row">
    <div class="col-md-12"  v-for="slogan in slogans" :key="slogan.id" >
      <p>{{ slogan.slmessage }}</p>
    </div>
  </div>
</template>

<script>
import Slogan from "../../apis/Slogan";

export default {
  data() {
    return {
      slogans: [],
    };
  },
  created() {
    this.getSlogan();
  },

  methods: {
    getSlogan() {
      Slogan.getSlogans().then((res) => {
        this.slogans = res.data;
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap');

.row{
  width: 100%;
  height: 50px;
  padding: 0;
  margin:0;
  background: rgba(104, 35, 153, 0.95);
   box-shadow: 0 0 10px rgb(255, 255, 255);
     position: fixed;
     z-index: 55;
}
.row .col-md-12 {

  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: inline;
}
.col-md-12 p {
  font-size: 1.2em;
  font-weight: 400;
   font-family: 'Gentium Book Basic', serif;
  letter-spacing: 1px;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  transform: translateX(100%);
  animation: text 25s linear infinite;
}

@keyframes text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 436px) {
  .row {
    display: none;
  }

}
</style>

