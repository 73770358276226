<template>
  <div class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h4>AGROTECH GLOBAL BD NUTRITION</h4>
          <hr />
          <div
            class="promo"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-once="false"
          >
            <p class="wrap">
              Company profile;
              <br />
              Greetings from Agrotech Global BD Nutrition!<br />
              It is an innovative, self driven and eminent company in the animal
              health sector in Bangladesh. It is established in 2019 with highly
              technical professionals related to the animal health sector. To
              serve quality products for healthy livestock and the nation is our
              principal motto. Food safety and health hygiene for men & animals
              is a challenging issue everywhere, especially in our country.
              Considering food safety, nutritions and health hygiene of farm
              animals and feed industries like poultry, cattle etc.
              <br />
              We have intended to provide technical and other logistic support
              by providing eminence products by importing from different pitches
              around the globe, especially from Korea, America or Europe.
              Quality products with competitive prices are our one of the
              foremost strengths. Our present customers are Nourish Poultry &
              hatcheries Ltd. Sadesh Farms Ltd, Provita Feed and hatcheries Ltd.
              Abir Poultry & Processed feed ltd and forecasting customer is Kazi
              Farms ltd. We have a good range of portfolio customers in the
              Bangladesh market. So now we have needed a sound company from you
              thus we can cover all aspect feed additives with proven quality
              report and you will exploit our technical and professional
              strength for making business relation with customers and create a
              win-win business relation in Bangladesh animal health market.
              <br />
              Hence, you are requested to join our team to grow together through
              a teamwork environment and snatch animal health market in
              Bangladesh.
              <br />
            </p>
          </div>
          <div
            class="policy"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-once="false"
          >
            <h5>Company type:</h5>
            <ul>
              <li>Distributor</li>
              <li>Importer</li>
              <li>Wholesaler</li>
            </ul>
          </div>
          <div
            class="services"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-once="false"
          >
            <h5>Product/Services:</h5>
            <ul>
              <ol type="1">
                <span> Veterinary :&nbsp;</span
                >Medicine and Vaccines supplies 
              </ol>
            </ul>
          </div>

        </div>
        <div
          class="col-md-4"
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-once="false"
        >
          <h4>Contact information</h4>
          <hr />
          <div class="details">
            <ul>
              <li><span> Name:</span> Agrotech Global BD Nutrition</li>
              <li class="mb-4">
                <span style="margin-right:70px">Address:</span>House:08,Road:MainRoad, <br />
                Eastern Housing (Pallabi 2nd Phase) Rupnagar
              </li>
              <li><span>Zip/Postal code:</span>1216</li>
              <li><span> City/Region:</span>Dhaka</li>
              <li><span>Country:</span>Bangladesh</li>
              <li><span>Telephone:</span>+880-1752-827682</li>
              <li><span>Email:</span>Contact us</li>
              <li><span>Website:</span> www.agrotechglobalbd.com</li>
              <li><span>Fax:</span> -</li>
              <li><span>Vat number</span> -</li>
              <li><span>Chamber of Commerce:</span> -</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="partners">
        <div class="row">
          <div class="col-md-3">
            <div class="icon">
              <i class="fa fa-adjust" aria-hidden="true"></i>
            </div>
          </div>
          <div class="col-md-9">
            <h4>Mission</h4>
            <p>
              Ensure Quality product beyond your expextation.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="icon">
              <i class="fa fa-adjust" aria-hidden="true"></i>
            </div>
          </div>
          <div class="col-md-9">
            <h4>Vision</h4>
            <p>
              Become a top quality animal health company by consistent
              development of product quality, one stop solution provider and
              satisfying customers
            </p>
          </div>
        </div>

        <div class="row2">
          <div class="col-md-12">
            <div class="icon">
              <i class="fa fa-adjust" aria-hidden="true"></i>
              <h4>Core Values</h4>
            </div>
          </div>
          <div class="col-md-12">
            <div class="box">
              <div
                class="row"
                data-aos="zoom-in-up"
                data-aos-delay="500"
                data-aos-once="false"
              >
                <div class="col-md-4">
                  <div class="card">
                    <h5>Farmers Centric</h5>
                    <p class="wrap">
                      We put highest priority in satisfying our farmers need .
                      We are proactive analyzing their needs so that we can
                      address their future needs & expectations.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <h5>Quality Focus</h5>
                    <p class="wrap">
                      We are committed to provide quality products and services
                      to our Farmers & stackholders .
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <h5>Drive for Results</h5>
                    <p class="wrap">
                      We are committed and focused to achieve specific and
                      agreed results that brings value to the organization.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <h5>Accountability</h5>
                    <p class="wrap">
                      We are accountable for providing contemporary, quality and
                      affordable products & service to our stakeholders
                      maintaining ethical & regulatory obligations.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <h5>Teamwork</h5>
                    <p class="wrap">
                      We move together to make our organization great/grand.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&family=Bebas+Neue&family=Gentium+Book+Basic&family=Lobster&family=Playfair+Display:wght@400;600&family=Source+Serif+Pro&display=swap');

.container {
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 130px;
}
.row {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
.col-md-8 {
  width: 60%;
  background: #fff;
  margin-bottom: 20px;
}
.col-md-4 {
  width: 40%;
  background: #fff;
  margin-bottom: 20px;
}
.col-md-8 h4,
.col-md-4 h4 {
  padding-top: 20px;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1px;
font-family: 'Gentium Book Basic', serif;
  text-align: left;
  color: #000;
  text-transform: uppercase;
}
.col-md-8 hr,
.col-md-4 hr {
  width: 80%;
  height: 4px;
  background: green;
  border-radius: 30%;
}
.col-md-8 .promo {
  width: 100%;
}
.col-md-8 .promo .wrap {
  font-size: 1.1rem;
  font-weight: 600;
 font-family: 'Gentium Book Basic', serif;
  text-align: justify;
  color: #000;
}
.col-md-4 .details {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.col-md-4 .details ul {
  width: 100%;
  height: 100%;
  padding: 0;
  margin-left: 20px;
  display: block;
}
.details ul li {
  padding: 5px;
  margin: 0;
  display: flex;
  font-size: 2rem;
  font-weight: 500;
}
.details ul li span {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
}
.col-md-8 .policy,
.col-md-8 .services {
  width: 100%;
  padding: 2px;
  margin: 2px;
}
.policy h5,
.services h5 {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  padding: 5px;
  margin: 0;
}
.policy ul,
.services ul {
  display: flex;
}
.policy ul li {
  font-size: 1.1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  margin: 10px;
  list-style: none;
  text-align: left;
}
.services ul ol {
  font-size: 1.1rem;
  font-weight: 600;
 font-family: 'Gentium Book Basic', serif;
  margin: 10px;
}
.services ul ol span {
  font-size: 1.1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
}
.col-md-8 .export .row {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.export .row .col-md-8 {
  width: 60%;
  height: 350px;
  padding: 5px;
  margin: 0;
  background: #fff;
  justify-content: left;
}
.export .row .col-md-8 h6 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  color: black;
  text-align: left;
  padding-left: 20px;
}
.export .row .col-md-8 .image {
  width: 380px;
  height: 300px;
  padding: 5px;
  margin: 0;
}
.export .row .col-md-8 .image img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.export .row .col-md-2 {
  width: 20%;
  justify-content: center;
}
.export .row .col-md-2 h6 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  font-family: 'Gentium Book Basic', serif;
}
.export .row .col-md-2 ul {
  display: block;
  justify-content: center;
}
.export .row .col-md-2 ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
}
.groth .row {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.groth .row .col-md-4 {
  width: 30%;
  height: 100px;
  padding: 5px;
  background: #fff;
  justify-content: center;
}
.groth .row .col-md-4 h6 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  font-family: 'Gentium Book Basic', serif;
  text-align: center;
}
.groth .row .col-md-4 ul {
  display: block;
  justify-content: center;
}
.groth .row .col-md-4 ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  text-align: center;
}
.row .col-md-4 .details {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
}
.row .col-md-4 .details ul {
  display: block;
  justify-content: center;
}
.row .col-md-4 .details ul li {
  height: 50px;
  list-style: none;
  padding: 5px;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  display: flex;
  text-align: left;
}
.row .col-md-4 .details ul li span {
  width: 180px;
  height: 40px;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
  text-align: left;
}
.partners .row {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 200px;
  box-shadow: 0 0 10px blue;
  padding: 5px;
  margin: 5px;
}
.partners .col-md-3 {
  width: 40%;
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
}
.partners .col-md-3 .icon {
  width: 100%;
  height: 150px;
  box-shadow: 1px 1px 3px #000;
  display: flex;
  justify-content: center;
}
.partners .col-md-3 i {
  font-size: 4rem;
  font-weight: 900;
  margin-top: 50px;
}
.partners .col-md-9 {
  width: 60%;
  background: #fff;
  margin-bottom: 20px;
}
.partners .col-md-9 h4 {
  padding-top: 50px;
  font-size: 1.5rem;
font-family: 'Gentium Book Basic', serif;
  font-weight: 700;
  text-align: left;
  color: #000;
  text-transform: uppercase;
}
.partners .col-md-9 p {
  font-size: 1rem;
  font-family: 'Gentium Book Basic', serif;
  font-weight: 600;
  text-align: justify;
}
/* first row end */
.row2 {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
  margin-left: -10px;
}
.row2 h4 {
  padding-top: 30px;
  font-size: 1.5rem;
  font-weight: 600;
 font-family: 'Gentium Book Basic', serif;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  margin: 5px;
}
.row2 .col-md-12 {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 10px;
}
.row2 .col-md-12 .icon {
  width: 100%;
  height: 100px;
  border: 2px solid #000;
  background: rgb(4, 4, 56);
  display: flex;
  justify-content: center;
}
.row2 .col-md-12 .icon i {
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
  padding: 5px;
}
.box .row {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.box .col-md-4 {
  width: 30%;
  height: 200px;
  display: block;
  justify-content: left;
  padding: 10px;
  margin: 10px;
}
.box .col-md-4 .card {
  width: 300px;
  height: 200px;
  padding: 10px;
  box-shadow: 0 0 15px blue;
}
.box .col-md-4 .card h5 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
font-family: 'Gentium Book Basic', serif;
  padding: 5px;
}
.box .col-md-4 .card p {
  padding: 5px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Gentium Book Basic', serif;
}
.row3 {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  margin-top: -100px;
  margin-left: -10px;
}

@media screen and (max-width: 436px) {
  .container {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 130px;
  }
  .row {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .col-md-8 {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
  }
  .col-md-4 {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
  }
  .col-md-8 h4,
  .col-md-4 h4 {
    padding-top: 20px;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'Gentium Book Basic', serif;
    text-align: left;
    color: #000;
    text-transform: uppercase;
  }
  .col-md-8 hr,
  .col-md-4 hr {
    width: 80%;
    height: 4px;
    background: green;
    border-radius: 30%;
  }
  .col-md-8 .promo {
    width: 100%;
  }
  .col-md-8 .promo .wrap {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    text-align: justify;
    color: #000;
  }
  .col-md-4 .details {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .col-md-4 .details ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-left: 20px;
    display: block;
  }
  .details ul li {
    padding: 5px;
    margin: 0;
    display: flex;
    font-size: 1.3rem;
    
    font-weight: 500;
  }
  .details ul li span {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
  }
  .col-md-8 .policy,
  .col-md-8 .services {
    width: 100%;
    padding: 2px;
    margin: 2px;
  }
  .policy h5,
  .services h5 {
    font-size: 1.4rem;
    font-weight: 600;
     font-family: 'Gentium Book Basic', serif;
    padding: 5px;
    margin: 0;
  }
  .policy ul,
  .services ul {
    display: flex;
  }
  .policy ul li {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    margin: 10px;
    list-style: none;
    text-align: left;
  }
  .services ul ol {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    margin: 10px;
  }
  .services ul ol span {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
  }
  .col-md-8 .export .row {
    position: relative;
    display: flex;
    justify-content: space-around;
  }
  .export .row .col-md-8 {
    width: 100%;
    height: 350px;
    padding: 5px;
    margin: 0;
    background: #fff;
    justify-content: left;
  }
  .export .row .col-md-8 h6 {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    color: black;
    text-align: left;
    padding-left: 20px;
  }
  .export .row .col-md-8 .image {
    width: 380px;
    height: 300px;
    padding: 10px;
    margin: 0;
  }
  .export .row .col-md-8 .image img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
  .export .row .col-md-2 {
    width: 100%;
    justify-content: center;
  }
  .export .row .col-md-2 h6 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    font-family: 'Gentium Book Basic', serif;
  }
  .export .row .col-md-2 ul {
    display: block;
    justify-content: center;
  }
  .export .row .col-md-2 ul li {
    list-style: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
  }
  .groth .row {
    position: relative;
    display: flex;
    justify-content: space-around;
  }
  .groth .row .col-md-4 {
    width: 30%;
    height: 100px;
    padding: 5px;
    background: #fff;
    justify-content: center;
  }
  .groth .row .col-md-4 h6 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    font-family: 'Gentium Book Basic', serif;
    text-align: center;
  }
  .groth .row .col-md-4 ul {
    display: block;
    justify-content: center;
  }
  .groth .row .col-md-4 ul li {
    list-style: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    text-align: center;
  }
  .row .col-md-4 .details {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
  }
  .row .col-md-4 .details ul {
    display: block;
    justify-content: center;
  }
  .row .col-md-4 .details ul li {
    height: 50px;
    list-style: none;
    padding: 5px;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    display: flex;
    text-align: left;
  }
  .row .col-md-4 .details ul li span {
    width: 180px;
    height: 40px;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    text-align: left;
  }
  .partners .row {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px blue;
    padding: 5px;
    margin: 5px;
  }
  .partners .col-md-3 {
    width: 100%;
    background: #fff;
    margin-bottom: 0;
    padding: 20px;
  }
  .partners .col-md-3 .icon {
    width: 100%;
    height: 150px;
    box-shadow: 1px 1px 3px #000;
    display: flex;
    justify-content: center;
  }
  .partners .col-md-3 i {
    font-size: 4rem;
    font-weight: 900;
    margin-top: 50px;
  }
  .partners .col-md-9 {
    width: 100%;
    background: #fff;
    margin-bottom: 0;
  }
  .partners .col-md-9 h4 {
    padding-top: 20px;
    font-size: 1.5rem;
    font-family: 'Gentium Book Basic', serif;
    font-weight: 700;
    text-align: center;
    color: #000;
    text-transform: uppercase;
  }
  .partners .col-md-9 p {
    font-size: 1rem;
    font-family: 'Gentium Book Basic', serif;
    font-weight: 600;
    text-align: justify;
  }
  /* first row end */
  .row2 {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    padding: 0;
  }
  .row2 h4 {
    padding-top: 30px;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
    margin: 5px;
  }
  .row2 .col-md-12 {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 10px;
    margin: 0;
  }
  .row2 .col-md-12 .icon {
    width: 100%;
    height: 100px;
    border: 2px solid #000;
    background: rgb(4, 4, 56);
    display: flex;
    justify-content: center;
  }
  .row2 .col-md-12 .icon i {
    font-size: 5rem;
    font-weight: 900;
    color: #fff;
    padding: 5px;
  }
  .box .row {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
  }
  .box .col-md-4 {
    width: 100%;
    height: 200px;
    display: block;
    justify-content: left;
    padding: 10px;
    margin: 0;
  }
  .box .col-md-4 .card {
    width: 100%;
    height: 200px;
    padding: 10px;
    box-shadow: 0 0 15px blue;
  }
  .box .col-md-4 .card h5 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Gentium Book Basic', serif;
    padding: 5px;
  }
  .box .col-md-4 .card p {
    padding: 5px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Gentium Book Basic', serif;
  }
  .row3 {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
    margin-top: -100px;
    margin-left: 0;
  }
}
</style>