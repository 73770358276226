<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app {
  width: 100%;
  height: 100%;
  background: rgb(238, 234, 234);
}

</style>
